module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"E:/Work/projects/beewo/bp-raumconcept/node_modules/gatsby-remark-images","id":"3450f110-2cfa-5b12-8a45-8d964eeb5e0c","name":"gatsby-remark-images","version":"3.3.0","pluginOptions":{"plugins":[],"maxWidth":1920},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"name":"B&P RAUM-Concept GmbH","short_name":"bp-raumconcept","start_url":"/","background_color":"#8bd8ed","theme_color":"#8bd8ed","display":"minimal-ui","icon":"src/images/logo.svg"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
